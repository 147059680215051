import { ICalendlyForm } from '@/utils/interfaces/components';
import { useRef } from 'react';
import { InlineWidget } from 'react-calendly';
import QS from 'query-string';

interface IProps {
	blok: ICalendlyForm;
}

export const FormCalendly: React.FC<IProps> = ({ blok }) => {
	const ref = useRef<HTMLDivElement>(null);

	const queryString = QS.stringify({
		text_color: blok.text_color,
		primary_color: blok.primary_color,
	});

	return (
		<div>
			<div ref={ref} className="h-[1200px]">
				<InlineWidget
					styles={{
						width: '100%',
						height: '100%',
					}}
					url={`${blok.url}?${queryString}`}
				/>
			</div>
		</div>
	);
};
