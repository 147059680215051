import { Container } from '@/components/Shared/Layout/Container';
import { SwiperNav } from '@/components/Shared/Swiper/SwiperNav';
import { Testimonial } from '@/components/Shared/Testimonial';
import { ITestimonials } from '@/utils/interfaces/bloks';
import { useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FormattedText } from '../Shared/FormattedText';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: ITestimonials;
	preview: boolean;
}

export const Testimonials: React.FC<IProps> = ({ blok, preview }) => {
	const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
	const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
	const [paginationRef, setPaginationRef] = useState<HTMLElement | null>(
		null,
	);

	const sectionClasses = ['relative py-14 md:py-32 xl:py-36 overflow-hidden'];
	const copyClasses = ['mt-4 lg:text-xl max-w-[600px]'];
	if (blok.style === 'light') {
		sectionClasses.push('bg-light');
		copyClasses.push('text-gray-dark/80');
	} else if (blok.style === 'dark') {
		sectionClasses.push('bg-green-dark text-white');
	}
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames={sectionClasses.join(' ')}
		>
			<div className="absolute inset-x-0 bottom-0 flex rotate-90 h-screen-width md:rotate-0 md:left-1/2 md:top-0 md:h-auto">
				<img
					className="object-cover object-left w-full h-full md:w-auto grow-0 shrink-0"
					src="/brand/backgrounds/testimonialsBackground.svg"
				/>
				<span className="md:block bg-[#20c49a] block w-full" />
			</div>
			<Container>
				<div className="text-center md:text-left">
					<FormattedText
						tag="h2"
						text={blok.heading}
						className="text-inherit"
					/>
					<p className={copyClasses.join(' ')}>{blok.description}</p>
				</div>
				<div className="mt-6 overflow-hidden lg:mt-19">
					<Swiper
						modules={[Navigation, Pagination]}
						navigation={{ prevEl, nextEl }}
						pagination={{
							el: paginationRef,
							type: 'fraction',
						}}
						spaceBetween={12}
					>
						{blok.testimonials.map((testimonial, key) => (
							<SwiperSlide
								className="flex h-auto min-h-[388px]"
								key={key}
							>
								<Testimonial
									blok={testimonial}
									isLarge={true}
								/>
							</SwiperSlide>
						))}
					</Swiper>
					{blok.testimonials.length > 1 && (
						<SwiperNav
							prevRef={(node) => setPrevEl(node)}
							nextRef={(node) => setNextEl(node)}
							paginationRef={(node) => setPaginationRef(node)}
						/>
					)}
				</div>
			</Container>
		</SbSection>
	);
};
