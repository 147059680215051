import { HeroContent } from '@/components/Bloks/Hero/HeroContent';
import { SbSection } from '@/components/Shared/Layout/SbSection';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { IHeroBlok } from '@/utils/interfaces/bloks';
import { SbImageWrapper } from '@/components/Shared/Image/SbImageWrapper';

interface IProps {
	blok: IHeroBlok;
	preview: boolean;
}

export const HeroStandard: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative lg:flex items-center bg-green-dark text-white bg-no-repeat lg:min-h-[600px] overflow-hidden"
		>
			<div className="absolute inset-x-0 top-0 bottom-1/2 lg:bottom-0 lg:left-1/2">
				<div className="absolute -rotate-90 h-screen-width -translate-y-1/4 lg:translate-y-0 lg:rotate-0 lg:inset-0 lg:h-auto">
					<img
						className="object-cover object-left-bottom w-full h-full"
						src="/brand/backgrounds/heroStandardBackground.svg"
					/>
				</div>
			</div>
			<div className="inset-y-0 right-0 items-center pt-6 left-1/2 lg:absolute lg:flex lg:py-12">
				<div className="relative w-full mx-auto sm:max-w-[645px] h-[220px] sm:h-[410px]">
					<div className="w-full h-full">
						<SbImageWrapper shadow={false} rounded="xl">
							<SbImage
								src={blok.image.filename}
								layout="fill"
								objectFit="contain"
								alt={blok.image.alt}
							/>
						</SbImageWrapper>
					</div>
				</div>
			</div>
			<HeroContent blok={blok} />
		</SbSection>
	);
};
